<template lang="html">
  <div
    id="vue-gf-tag-components-completion"
  >
    <CompletionList
      :api-domain="apiDomain"
      :demo="demo"
      :fundraising-page-id="fundraisingPageId"
      :event-id="eventId"
      :region-id="regionId"
    />
  </div>
</template>

<script>

import GfComponentBase from './GfComponentBase';
import CompletionList from './components/Completions/CompletionList';

export default {
  name: 'GfCompletion',
  components: {CompletionList},
  mixins: [GfComponentBase],
  mounted: function() {
    this.$gfLogger.logInfo("Mounted GfCompletion");
  }
}
</script>

<style lang="css">

</style>
