import WellKnownActionHandler from "./WellKnownActionHandler";
import { logger } from '@gf/gf-logger';
export default class UpdateFrpImages extends WellKnownActionHandler {
  constructor() {
    super();
  }

  handle(options) {
    logger.logInfo("Handling update frp image");
    var updateProfileImgBtnId = document.getElementById("edit-profile-image-btn");
    if (updateProfileImgBtnId)
      updateProfileImgBtnId.click();
  }
}