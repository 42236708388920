import WellKnownActionHandler from "./WellKnownActionHandler";
import { logger } from '@gf/gf-logger';
export default class PostUpdate extends WellKnownActionHandler {
  constructor() {
    super();
  }

  handle(options) {
    logger.logInfo("Handling Posting of update");
    var element = document.getElementById("publishBlogPost");
    if (element)
      element.click();
  }
}