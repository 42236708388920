<template>
  <div
    v-if="enabled"
    class="gf-tc-completion-list"
  >
    <div
      class="gf-driver-button"
      style="display:none"
    />
    <div class="row header-3 font-weight-bold">
      <div class="col mb-2 mx-auto text-center">
        {{ title }}
      </div>
    </div>
    <div class="col-12">
      <radial-progress-bar
        class="col mx-auto w-100 text-center"
        :diameter="200"
        :completed-steps="percentageComplete"
        :total-steps="100"
        :stroke-width="15"
        :inner-stroke-width="15"
        :inner-stroke-color="trackColour"
        :start-color="driverBgColour"
        :stop-color="driverBgColour"
      >
        <div class="text-center gf-driver-text">
          <span class="h3 font-weight-bold">{{ percentageComplete }}%</span>
        </div>
      </radial-progress-bar>
    </div>
    <div class="col-12 align-self-center">
      <table
        v-if="completions.length > 0"
        class="table table-hover table-bordered"
      >
        <tbody>
          <tr
            v-for="(item, key) in completions"
            :key="key"
            class=""
          >
            <td
              class="gf-body-text-face border-right-0"
              :class="{'pointer': hasInactiveAction(item)}"         
              @click="callHandler(item)"
            >
              <s v-if="item.active">{{ item.description }}</s>
              <span v-if="!item.active">{{ item.description }}</span>
            </td>
            <td class="border-left-0 text-right pr-3">
              <span
                v-show="item.active"
              ><font-awesome-icon
                :icon="completedIcon"
                class="gf-driver-background-fg font-weight-bold"
              /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import constants from "../../common/constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import RadialProgressBar from "vue-radial-progress";
import gfWellknownActions from "../../common/wellKnownActions/gfWellKnownActions";

export default {
  name: "CompletionList",
  components: {
    FontAwesomeIcon,
    RadialProgressBar
  },
  props: {
    apiDomain: {
      type: String,
      required: true
    },
    regionId: {
      type: String,
      required: true
    },
    eventId: {
      type: Number,
      default: 0
    },
    fundraisingPageId: {
      type: Number,
      default: 0
    },
    demo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: "My Achievements",
      completions: [],
      enabled: false,
      completedIcon: faCheck,
      driverBgColour: "",
      trackColour: "rgba(0,0,0,0.1)",
      gfWellknownActions: gfWellknownActions
    };
  },
  computed: {
    percentageComplete() {
      if (this.completions.length == 0) return 0;
      return Math.round(
        (this.completions.filter(x => x.active).length /
          this.completions.length) *
          100
      );
    }
  },

  mounted() {
    this.$nextTick(() => this.loadCompletion());
    this.loadDriverBtnColour();
  },

  methods: {
    hasInactiveAction(item) {
      return !item.active && item.inActiveAction && item.inActiveAction.type && item.inActiveAction.type !== ''
    },

    callHandler(item) {
      if (this.hasInactiveAction(item)) {
        if (item.inActiveAction.type == constants.WELL_KNOWN_ACTION_TYPE && item.inActiveAction.value) {
          var action = JSON.parse(item.inActiveAction.value)
          this.$gfLogger.logInfo("Parsed well known action:", action);
          this.gfWellknownActions.handle(action.name, action.options);
        }
      }
    },

    loadDriverBtnColour() {
      setTimeout(() => {
        try {
          this.driverBgColour = getComputedStyle(
            document.getElementsByClassName("gf-driver-button")[0],
            null
          ).getPropertyValue("background-color");
          this.trackColour = this.getRgb(this.driverBgColour).replace('rgb', 'rgba').replace(')', ', 0.1)');
        } catch (e) {
          this.driverBgColour = "#bbff42";
        }
      }, 1);
    },
    getRgb(colour) {
      if (colour.startsWith("rgb")) return colour;
      if (/^#([a-f0-9]{3}){1,2}$/.test(colour)) {
        if (colour.length == 4) {
          colour =
            "#" +
            [
              colour[1],
              colour[1],
              colour[2],
              colour[2],
              colour[3],
              colour[3]
            ].join("");
        }
        var c = "0x" + colour.substring(1);
        return (
          "rgb(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ")"
        );
      }
    },

    async loadCompletion() {
      await this.loadCompletionConfiguration();

      if (this.completions.length == 0) return;

      var results = await this.$gfApiServices.pagesService.searchById(
        this.fundraisingPageId
      );

      this.$gfLogger.logInfo(
        "CompletionList.loadCompletion(), pages search results",
        results
      );

      if (!results || results.TotalPages == 0) return;

      gfWellknownActions.init({
        pageDonateUrl: results.Pages[0].DonationUrl
      });

      var tags = results.Pages[0].PageTags;
      if (!tags || tags.length == 0) return;

      this.$gfLogger.logInfo(
        "CompletionList.loadCompletion(), tags on page",
        tags
      );
      this.$gfLogger.logInfo(
        "CompletionList.loadCompletion(), configured completions",
        this.completions
      );

      if (!tags || tags.length == 0) return;

      tags.forEach(tag => {
        var matchedTag = this.completions.find(x => x.id == tag.TagId);
        if (matchedTag) matchedTag.active = true;
      });
    },

    async loadCompletionConfiguration() {
      var requestData = {
        SettingsContext: {
          FundraisingPageId: this.fundraisingPageId,
          EventId: this.eventId,
          Region: this.regionId
        },
        Types: [
          {
            ConfigurationName: constants.CONFIGURATION_FUNDRAISING_TYPE,
            ConfigurationSubType:
              constants.CONFIGURATION_COMPLETION_DISPLAY_SUB_TYPE
          }
        ]
      };
      var results = await this.$gfApiServices.configurationService.getSettings(
        requestData
      );
      var configuration = results.Settings.find(
        x =>
          x.ConfigurationSubType ==
          constants.CONFIGURATION_COMPLETION_DISPLAY_SUB_TYPE
      );
      this.$gfLogger.logInfo(
        "CompletionList.loadCompletionConfiguration(), completion configuration",
        configuration
      );

      if (!configuration) return;

      this.title = configuration.Settings.Title;
      this.completions = [];
      this.enabled = configuration.Settings.IsEnabled;
      configuration.Settings.Completions.forEach(item => {
        this.completions.push({
          id: parseInt(item.Entity.Value),
          name: item.TagName,
          image: item.ImageUrl,
          description: item.Description,
          active: false,
          inActiveAction: {
            type:item.InactiveTarget.Type,
            value: item.InactiveTarget.Value
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.gf-bl-badge {
  max-height: 100px;
}

.pointer {
  cursor: pointer;
}
</style>
