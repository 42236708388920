import WellKnownActionHandler from "./WellKnownActionHandler";
import { logger } from '@gf/gf-logger';
export default class ConnectFitness extends WellKnownActionHandler {
  constructor() {
    super();
  }

  handle(options) {
    logger.logInfo("Handling Connect Fitness");
    var element = document.getElementById("btnConnectApp");
    if (element)
      element.click();
  }
}