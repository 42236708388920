import wellKnownActionsConstants from "./wellKnownActionsConstants";
import { DonateToPage, PostUpdate, LogActivity, UpdateFrpImage, ShareDonations, UpdatePageStory, UpdatePageTitle, ConnectFitness }  from './actionHandlers/WellKnownActionHandlers';

export default {
    Actions: [
        {
            Name: wellKnownActionsConstants.CONNECT_FITNESS,
            Handler: ConnectFitness
        },
        {
            Name: wellKnownActionsConstants.DONATE_TO_PAGE,
            Handler: DonateToPage
        },
        {
            Name: wellKnownActionsConstants.LOG_ACTIVITY,
            Handler: LogActivity
        },
        {
            Name: wellKnownActionsConstants.POST_UPDATE,
            Handler: PostUpdate
        },
        {
            Name: wellKnownActionsConstants.SHARE_RECEIVE_DONATIONS,
            Handler: ShareDonations
        },
        {
            Name: wellKnownActionsConstants.UPDATE_FRP_IMAGE,
            Handler: UpdateFrpImage
        },
        {
            Name: wellKnownActionsConstants.UPDATE_FRP_STORY,
            Handler: UpdatePageStory
        },
        {
            Name: wellKnownActionsConstants.UPDATE_FRP_TITLE,
            Handler: UpdatePageTitle
        }
    ]
}
