<template lang="html">
  <div
    id="vue-gf-tag-components"
    class="col"
  >
    <BadgeList
      :api-domain="apiDomain"
      :demo="demo"
      :fundraising-page-id="fundraisingPageId"
      :event-id="eventId"
      :region-id="regionId"
    />
  </div>
</template>

<script>
import GfComponentBase from "./GfComponentBase";
import BadgeList from "./components/Badge/BadgeList";

export default {
  name: "GfBadges",
  components: { BadgeList },
  mixins: [GfComponentBase],
  mounted: function() {
    this.$gfLogger.logInfo("Mounted GfBadgeDisplay");
  },

  methods: {}
};
</script>

<style lang="css"></style>
