import WellKnownActionHandler from "./WellKnownActionHandler";
import { logger } from '@gf/gf-logger';
export default class PostUpdate extends WellKnownActionHandler {
  constructor() {
    super();
  }

  handle(options) {
    logger.logInfo("Handling Posting of update");
    var element = document.getElementsByClassName("fb-share-button");
    if (element && element.length > 0)
      element[0].click();
  }
}