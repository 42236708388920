export default {
  CONNECT_FITNESS: "CONNECT_FITNESS",
  DONATE_TO_PAGE: "DONATE_TO_PAGE",
  LOG_ACTIVITY: "LOG_ACTIVITY",
  POST_UPDATE: "POST_UPDATE",
  SHARE_RECEIVE_DONATIONS: "SHARE_RECEIVE_DONATIONS",
  UPDATE_FRP_IMAGE: "UPDATE_FRP_IMAGE",
  UPDATE_FRP_STORY: "UPDATE_FRP_STORY",
  UPDATE_FRP_TITLE: "UPDATE_FRP_TITLE"
};
