import WellKnownActionHandler from "./WellKnownActionHandler";
import { logger } from '@gf/gf-logger';
export default class UpdatePageTitle extends WellKnownActionHandler {
  constructor() {
    super();
  }

  handle(options) {
    logger.logInfo("Handling update of page title");
    var element = document.getElementsByClassName("edit-detail");
    if (element && element.length > 0) {
      for (let e of element) {
        if (e.dataset && e.dataset.field && e.dataset.field == 'cause-title')
          e.click();
      };
    }
  }
}
