import WellKnownActionHandler from "./WellKnownActionHandler";
import { logger } from '@gf/gf-logger';
import store from '../wellKnownActionsStore';

export default class DonateToPage extends WellKnownActionHandler {
  constructor() {
    super();
  }

  handle(options) {
    logger.logInfo(`Donate to page url: ${store.state.pageDonateUrl}`);
    window.location.href=store.state.pageDonateUrl;
  }
}