<script>
import Vue from "vue";
import { VueLoggerPlugin } from "@gf/gf-logger"
import gfApiServices from "@gf/gf-api-services"

Vue.use(VueLoggerPlugin);

export default {
  name: 'GfComponentBase',
  props:  {
    apiDomain: {
      type: String,
      default: "api.gofundraise.com.au"
    },
    regionCode: {
      type: String,
      default: "AU"
    },
    regionId: {
      type: String,
      default: "EEDD16D2-38B3-459E-B2D4-5BA898524018"
    },
    eventId: {
      type: Number,
      default: 0
    },
    fundraisingPageId: {
      type: Number,
      default: 0
    },
    userId: {
      type: Number,
      default: 0
    },
    demo: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.demo)
      this.$gfLogger.logInfo("In Demo Mode");

    Vue.use(gfApiServices.GfApiServicesPlugin, {logger: this.$gfLogger, apiDomain: this.apiDomain, demo: this.demo});
  }
}
</script>
