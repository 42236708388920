<template>
  <div :class="'gf-tc-badge gf-tc-badge-' + name">
    <div
      class="col px-1"
      :class="[{'active': active}, activeClass, inactiveClass]"
    >
      <div class="row gf-tc-badge-img mx-auto">
        <img
          :src="imageUri"
          class="w-100"
          :class="{'inactive': !active}"
          :alt="description"
        >
      </div>
      <div class="row text-center gf-tc-badge-desc col mx-auto px-0 small pt-1">
        <div
          class="col px-0"
          :class="{'inactive': !active}"
        >
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Badge",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ""
    },
    descriptionHoverText: {
      type: String,
      default: ''
    },
    imageUri: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    badgeClass: {
      type: [String, Object],
      default: () => ({
        active: '',
        inactive: ''
      })
    },
    imageEffects: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      title: "My Achievements"
    };
  },
  computed: {
    activeClass() {
      return this.active ? this.badgeClass.active : ''
    },
    inactiveClass() {
      return ! this.active ? this.badgeClass.inactive : ''
    }
  }
};
</script>
<style scoped>
.gf-tc-badge-img img{
  max-height: 100px;
  object-fit: contain;
}

.gf-tc-badge-img img.inactive{
  filter: grayscale(100%) opacity(0.4);;
}

.gf-tc-badge-img img.inactive{
  filter: grayscale(100%) opacity(0.4);;
}

.gf-tc-badge-desc .inactive {
  filter: opacity(0.5)
}
</style>