import connectFitness from "./ConnectFitness";
import donateToPage from "./DonateToPage";
import logActivity from "./LogActivity";
import postUpdate from "./PostUpdate";
import shareDonations from "./ShareDonations";
import updateFrpImage from "./UpdateFrpImage";
import updatePageStory from "./UpdatePageStory";
import updatePageTitle from "./UpdatePageTitle";

const ConnectFitness = new connectFitness();
const DonateToPage = new donateToPage();
const LogActivity = new logActivity();
const PostUpdate = new postUpdate();
const ShareDonations = new shareDonations();
const UpdateFrpImage = new updateFrpImage();
const UpdatePageStory = new updatePageStory();
const UpdatePageTitle = new updatePageTitle();

export {
  ConnectFitness,
  DonateToPage,
  LogActivity,
  PostUpdate,
  ShareDonations,
  UpdateFrpImage,
  UpdatePageStory,
  UpdatePageTitle
}