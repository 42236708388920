import wellKnownActionsStore from "./wellKnownActionsStore.js"
import wellKnownActions from "./wellKnownActions.js";

export default {
    wellKnownActions: wellKnownActions,

    init: function (options) {
        if (options) {
            this.configureOptions(options)
        }
    },
    
    configureOptions: function (options) {
        if (options.pageDonateUrl && !wellKnownActionsStore.state.configureOptions)
            wellKnownActionsStore.setPageDonateUrl(options.pageDonateUrl);
    },

    handle: function (action, options) {
        var item = this.wellKnownActions.Actions.find(a => a.Name == action)
        if (item)
            item.Handler.handle(options)
    }
}