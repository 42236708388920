<template>
  <div
    v-if="enabled"
    class="gf-tc-badge-list"
  >
    <div
      class="row header-3 font-weight-bold"
    >
      <div class="col mb-2">
        {{ title }}
      </div>
    </div>

    <div
      v-if="badges.length > 0"
      class="row"
    >
      <div
        v-for="(badge, key) in badges"
        :key="key"
        class="col-3 col-lg-2 py-1 px-0"
        :class="{'d-none': badge.hideIfInactive && !badge.active}"
      >
        <Badge
          :image-uri="badge.image"
          :name="badge.name"
          :active="badge.active"
          :description="badge.description"
          :badge-class="badge.classes"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Badge from "./Badge"
import constants from '../../common/constants';
export default {
  name: "BadgeList",

  components: {
    Badge
  },
  props: {
    apiDomain: {
      type: String,
      required: true
    },
    regionId: {
      type: String,
      required: true
    },
    eventId: {
      type: Number,
      default: 0
    },
    fundraisingPageId: {
      type: Number,
      default: 0
    },
    demo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: "My Achievements",
      badges: [],
      enabled: false
    };
  },

  mounted() {
    this.$nextTick(() => this.loadBadges());
  },

  methods: {
    async loadBadges() {
      await this.loadBadgeConfiguration();

      if (this.badges.length == 0)
        return;

      var results = await this.$gfApiServices.pagesService.searchById(this.fundraisingPageId);
      this.$gfLogger.logInfo("BadgeList.loadBadges(), pages search results", results);

      if (!results || results.TotalPages == 0)
        return;


      var tags = results.Pages[0].PageTags;
      if (!tags || tags.length == 0)
        return;

      tags.forEach(tag => {
        var matchedBadge = this.badges.find(x=>x.id == tag.TagId);
        if (matchedBadge)
          matchedBadge.active = true;
      });
    },

    async loadBadgeConfiguration() {
      var requestData = {
        SettingsContext: {
          FundraisingPageId: this.fundraisingPageId,
          EventId: this.eventId,
          Region: this.regionId
        },
        Types: [
          {
            ConfigurationName: constants.CONFIGURATION_FUNDRAISING_TYPE,
            ConfigurationSubType: constants.CONFIGURATION_BADGE_DISPLAY_SUB_TYPE
          }
        ]
      };

      var results = await this.$gfApiServices.configurationService.getSettings(requestData);
      var badgeConfiguration = results.Settings.find(x=>x.ConfigurationSubType == constants.CONFIGURATION_BADGE_DISPLAY_SUB_TYPE)
      this.$gfLogger.logInfo("BadgeList.loadBadgeConfiguration(), badge configuration", badgeConfiguration);

      if (!badgeConfiguration)
        return;

      this.title = badgeConfiguration.Settings.Title
      this.enabled = badgeConfiguration.Settings.IsEnabled
      this.badges = [];
      badgeConfiguration.Settings.Badges.forEach(badge => {
        this.badges.push({
          id: parseInt(badge.Entity.Value),
          name: badge.Description.toLowerCase().replace(/\s/g, '-'),
          image: badge.ImageUrl,
          description: badge.Description,
          active: false,
          hideIfInactive: badge.HideIfInactive,
          classes: {
            active: badge.ActiveClass,
            inactive: badge.InactiveClass
          }
        });
      });
    }
  }
};
</script>
<style scoped>
.gf-bl-badge {
  max-height: 100px;
}
</style>
